import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import "./postabbrevgrid.css";
import Image from "gatsby-image";

function PostAbbrevGrid({ slug, title, cover, description, type }) {
  return (
    <article className="post-article-grid">
      <Link style={{ boxShadow: "none" }} to={slug} rel="bookmark">
        {cover && (
          <div className="post-image-grid">
            {type === "text" && <div className="post-type-text" title="Text post" />}
            {type === "video" && <div className="post-type-video" title="Video post" />}
            <Image
              fluid={cover.childImageSharp.fluid}
              alt="Featured Image"
              className="post-image-grid"
              imgStyle={{
                borderRadius: `0%`
              }}
            />
          </div>
        )}
        <header className="post-header-grid">
          <Link style={{ boxShadow: "none" }} to={slug} rel="bookmark">
            <h3 className="post-grid-title">
              {/* {type === "text" && <div className="post-type-text" title="Text post" />}
              {type === "video" && <div className="post-type-video" title="Video post" />} */}
              {title}
            </h3>
            <span className="post-grid-desc">{description}</span>
          </Link>
        </header>
      </Link>
    </article>
  );
}

PostAbbrevGrid.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string,
  cover: PropTypes.object,
  description: PropTypes.string,
  type: PropTypes.string
};

PostAbbrevGrid.defaultProps = {
  title: null,
  cover: null,
  description: "",
  type: ""
};

export default PostAbbrevGrid;

