const isAlphabetNum = s =>
  /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g.test(s);

function kebabCase(s) {
  if (isAlphabetNum(s)) {
    return s
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join("-");
  }

  return s
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();
}

const getSlug = str => {
  if (!str) return `/${str}/`;
  let newSlug = "";
  const tempLen = str.split("/").length;
  if (str.endsWith("/")) {
    newSlug = str.split("/")[tempLen - 2];
  } else {
    newSlug = str.split("/")[tempLen - 1];
  }
  return `/${newSlug}/`;
};

module.exports = {
  kebabCase,
  getSlug
};

