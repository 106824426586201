import React from "react";
import PropTypes from "prop-types";
import PostAbbrevGrid from "components/PostAbbrevGrid";
import "./relativeposts.css";
import { getSlug } from "utils/helpers";

function RelativePosts({ postNodes }) {
  const postNodesNotNull = postNodes.filter(x => x);

  if (postNodesNotNull.length === 0) {
    return null;
  }

  return (
    <div>
      <h3 style={{ margin: `0` }}>Related Posts:</h3>
      <div className="relative-posts-container">
        {postNodesNotNull.map(postNode => (
          <PostAbbrevGrid
            key={postNode.node.fields.slug}
            slug={getSlug(postNode.node.fields.slug)}
            title={postNode.node.frontmatter.title}
            date={postNode.node.frontmatter.date}
            tags={postNode.node.frontmatter.tags}
            timeToRead={postNode.node.timeToRead}
            cover={postNode.node.frontmatter.cover}
            type={postNode.node.frontmatter.contentType}
          />
        ))}
      </div>
    </div>
  );
}

RelativePosts.propTypes = {
  postNodes: PropTypes.array
};

RelativePosts.defaultProps = {
  postNodes: []
};

export default RelativePosts;

