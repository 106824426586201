import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import Image from "gatsby-image";
import SEO from "components/SEO";
import RelativePosts from "components/RelativePosts";
import { rhythm } from "utils/typography";

function BlogPostTemplate({ data, location }) {
  const post = data.markdownRemark;
  const allPosts = data.allMarkdownRemark;
  const siteTitle = data.site.siteMetadata.title;

  let relatedPosts = [];
  if (post && post.frontmatter.tags) {
    relatedPosts = allPosts.edges
      .filter(e => {
        for (const tag of post.frontmatter.tags) {
          if (
            e.node.frontmatter.tags.indexOf(tag) !== -1 &&
            e.node.fields.slug !== post.fields.slug
          ) {
            return true;
          }
        }
        return false;
      })
      .slice(0, 3);
  }

  return post === null ? null : (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(26)
        }}
      >
        <h1>{post.frontmatter.title}</h1>
        {post.frontmatter.cover && (
          <Image
            className="featured-image"
            fluid={post.frontmatter.cover.childImageSharp.fluid}
            alt="Featured Image"
          />
        )}
        <div dangerouslySetInnerHTML={{ __html: post.html }} />

        <div className="tags-share-container" style={{ marginTop: `70px`, border: `0` }}>
          <div
            className="share-container"
            style={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}
          >
            <a
              href={`https://twitter.com/intent/tweet?url=https://rangi.one${post.fields.slug}&text=${post.frontmatter.title} by @gagandeeprangi1`}
              target="blank"
              style={{ display: `flex` }}
            >
              Share this on Twitter <div className="twitter-icon" />
            </a>
          </div>
        </div>
      </div>

      <br />
      <br />

      <RelativePosts postNodes={relatedPosts} />
      <br />
    </Layout>
  );
}

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        lang
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        cover {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(filter: { frontmatter: { template: { in: ["post", "dev"] } } }) {
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            contentType
            cover {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

